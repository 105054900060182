import api from "./api";

const resourceService = {};
const endpoint = (resource) => `/resources/${resource}`;

resourceService.getCities = function () {
  return api.get(endpoint("cities")).then(({ data }) => data);
};

resourceService.getCountries = function () {
  return api.get(endpoint("countries")).then(({ data }) => data);
};

export default resourceService;
