import api from "./api";

const videoRoomService = {};
const endpoint = `/video-room`;

videoRoomService.createRoom = function ({ patientId, appointmentId }) {
  return api
    .post(endpoint, { patientId, appointmentId })
    .then((res) => res.data);
};

videoRoomService.getRoomAccessToken = function (roomId) {
  return api
    .get(`${endpoint}/access-token/${roomId}`)
    .then((res) => res.data.accessToken);
};

videoRoomService.getRoomInfo = function (roomId) {
  return api.get(`${endpoint}/${roomId}`).then((res) => res.data);
};

videoRoomService.startRoom = function (roomId) {
  return api.put(`${endpoint}/start-room/${roomId}`).then((res) => res.data);
};

videoRoomService.endRoom = function (roomId) {
  return api.put(`${endpoint}/end-room/${roomId}`).then((res) => res.data);
};

export default videoRoomService;
