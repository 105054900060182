<script>
import logo from "@/mixins/logo";
import DiagnosisSelect from "@/components/ui/DiagnosisSelect";
import helperService from "@/services/helper";
import { theme } from "@/services/config";

export default {
  name: "FormPrint",
  mixins: [logo],
  data() {
    return {
      selectedPatientForm: {},
      patient: {},
      tenant: theme,
    };
  },

  components: {
    DiagnosisSelect,
  },

  created() {
    this.selectedPatientForm = JSON.parse(localStorage.selectedPatientForm);
    this.patient = JSON.parse(localStorage.patient);
    window.print();
  },
  methods: {
    getFullName(patient) {
      return helperService.getFullName(patient);
    },
  },
};
</script>

<template lang="pug">

table.pdf-container
  .header
    img.header__logo(:src="logoUrl")
    .header__cols
      .header__cols-left
        h2 {{ tenant.toUpperCase() }} folio {{ selectedPatientForm._id }}
        h1 {{ selectedPatientForm.name }}
        h3 NO. DE EXPEDIENTE: {{ selectedPatientForm.patient }}
        h3 NOMBRE COMPLETO: {{ getFullName(patient) }}
        h3 EDAD: {{ patient.bornAt | age }}
        h3 FECHA DE NACIMIENTO: {{ patient.bornAt}}
        h3 DIRECCIÓN: {{ patient.address.street }}, {{ patient.address.city }}, {{ patient.address.district }}
        h3 INSTITUCIÓN: {{ patient.doctor.institutions ? patient.doctor.institutions[0].name : 'Sin institución' }}
      .header__cols-right
        h3 GENERO: {{patient.gender==='m' ? 'Hombre' : patient.gender==='f' ? 'Mujer' : 'Otro'}}
        h3 {{ patient.governmentId.type.toUpperCase() }}: {{ patient.governmentId.number }}
        h3 TELÉFONO: {{ patient.phone }}
        h3 ESTADO CIVIL: {{ patient.maritalStatus || 'N/A' }}
        h3 DR.: {{ getFullName(patient.doctor) }}
        h3 CÉDULA: {{ patient.doctor.professionalId || 'N/A' }}
  thead
    .header-space &nbsp;
  tbody
    .detail
      .modal__field(v-for='section in selectedPatientForm.sections' :key="section.name")
        fieldset.modal__row
          h2 {{ section.name }}
        
        .modal__row(v-for='question in section.questions' :key="question.name")
          fieldset.modal__field(v-if="question.type==='select' && !question.action")
            label.label {{ question.name }}
            el-input(:value="question.selected" readonly placeholder="Sin datos")

          fieldset.modal__field(v-if="question.action && question.action.type === 'indications' && question.action.subtype === 'diagnosis'")
            label.label {{ question.name }}
            DiagnosisSelect(
              :modelValue="question.selected"
              @update:modelValue="diagnosis => question.selected = diagnosis"
              readonly
            )

          fieldset.modal__field(v-if="question.type==='input'")
            label.label {{ question.name }}
            el-input(:value="question.selected" readonly placeholder="Sin datos")

          fieldset.modal__field(v-if="question.type==='inputNumber'")
            label.label {{ question.name }}
            el-input(:value="question.selected" readonly placeholder="Sin datos")
      
          fieldset.modal__field(v-if="question.type==='checkbox'")
            label.label {{ question.name }}
            el-checkbox-group(:value="question.selected")
              el-checkbox(
                v-for="answer in question.answers"
                :value="answer.value"
                :key="answer.value"
                :label="answer.value"
              )

          fieldset.modal__field(v-if="question.type ==='radio'")
            label.label {{ question.name }}
            el-radio-group(:value="question.selected")
              el-radio(
                readonly
                v-for="answer in question.answers",
                :key="answer.value",
                :label="answer.value"
                :class="answer.style && answer.style.color ? `border-${answer.style.color}` : ''",
              )
            span(v-if="question.observationEnable")
              label.label Especifique
              el-input(
                :value="question.observation",
              )

          fieldset.modal__field(v-if="question.type==='textarea'")
            label.label {{question.name}}
            el-input(
              readonly
              :value="question.selected",
              type="textarea"
              placeholder="Sin datos"
            )
        
        fieldset.modal__field(v-if="section.qualifier")
          h1 {{section.qualifier.name}} : {{ section.qualifierResult }}



  tfoot
    .footer-space &nbsp;


  .footer
    .signature
      h4 Firma
      h4 Aclaración

</template>

<style lang="scss">
@page {
  margin: 9mm 9mm 15mm;
  size: A4;
}

@media print {
  h1 {
    font-size: 10pt;
  }
  h2 {
    font-size: 7pt;
  }
  h3 {
    font-size: 9pt;
  }
  .header,
  .header-space {
    height: 250px;
  }
  .footer,
  .footer-space {
    height: 50px;
  }
  .header {
    padding: 0px 50px;
    position: fixed;
    top: 0;
  }
  .footer {
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .el-radio__input,
  .el-checkbox {
    -webkit-print-color-adjust: exact;
  }
}

.pdf-container {
  .header {
    &__logo {
      float: right;
      right: 100px;
      height: 50px;
    }

    &__cols {
      width: 70%;
      margin-top: 80px;
      display: flex;
      justify-content: space-between;

      &-left {
        margin-right: 20px;
      }

      &-right {
        margin-top: 40px;
      }
    }

    h1 {
      color: rgb(29, 47, 150);
      margin-bottom: 15px;
      text-align: left;
      font-size: 15vm;

      span {
        text-align: center;
      }
    }

    h3 {
      color: #000000;
      font-weight: 900;
      margin-top: 4px;
      font-size: 12vm;
    }
  }

  .detail {
    padding: 5px 45px;

    .detail-item {
      p {
        color: #000000;
        font-weight: 500;
        margin-top: 25px;
        font-size: 17px;
      }

      span {
        font-weight: 600;
      }
    }
  }

  .footer {
    .signature {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      h4 {
        color: #000000;
        margin-left: 100px;
        margin-right: 50px;
      }
    }
  }
}
</style>
