<script>
import treatmentService from "@/services/treatment";
import episodeService from "@/services/episode";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import Spinner from "@/components/ui/Spinner";
import segmentService from "@/services/segment";
import { capitalize } from "@/services/helper";

export default {
  name: "PatientTreatmentDetail",

  components: {
    Spinner,
  },

  data() {
    return {
      isFetching: false,
      isPosting: false,
      patientId: this.$route.params.id,
      showAssignedOnly: true,
      treatments: [],
      episodes: [],
      treatmentAssignments: [],
      treatmentAssignmentsDisabled: [],
      newTreatment: {
        id: "",
        startsAt: "",
      },
      filterRange: {
        from: "",
        to: "",
      },
    };
  },

  created() {
    this.isFetching = true;
    Promise.all([this.getTreatments(), this.getTreatmentAssignmets()]).finally(
      () => {
        this.isFetching = false;
      }
    );

    document.addEventListener("keyup", this.onKeyUp);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyUp);
  },

  watch: {
    showAssignedOnly() {
      this.getTreatmentAssignmets();
    },
  },

  computed: {
    filteredTreatmentAssignments() {
      if (!this.filterRange.from && !this.filterRange.to) {
        if (this.showAssignedOnly) {
          return this.treatmentAssignments.filter(
            ({ unassignedAt }) => !unassignedAt
          );
        } else {
          return this.treatmentAssignments;
        }
      }

      const from =
        this.filterRange.from && format(this.filterRange.from, "dd/MM/yyyy");
      const to =
        this.filterRange.to && format(this.filterRange.to, "dd/MM/yyyy");

      return this.treatmentAssignments.filter((treatment) => {
        const createdAt = format(treatment.createdAt, "dd/MM/yyyy");

        if (this.filterRange.from && this.filterRange.to) {
          return createdAt >= from && createdAt <= to;
        } else if (this.filterRange.from) {
          return createdAt >= from;
        } else {
          return createdAt <= to;
        }
      });
    },

    parsedTreatments() {
      return this.treatments.map((treatment) => {
        treatment.disabled = this.treatmentAssignments.some(
          (treatmentAssignment) => {
            return (
              treatmentAssignment.treatment &&
              treatmentAssignment.treatment._id === treatment._id &&
              !treatmentAssignment.unassignedAt
            );
          }
        );
        return treatment;
      });
    },
  },

  methods: {
    closeModal() {
      this.$router.back();
    },

    onKeyUp(event) {
      const esc = 27;
      if (event.keyCode == esc) {
        this.closeModal();
      }
    },

    getTreatments() {
      return treatmentService.get().then((res) => {
        this.treatments = res.docs;
      });
    },

    getTreatmentAssignmets() {
      // const defaultQuery = {
      //   populate: {
      //     path: "treatment",
      //     select: ["name", "description"],
      //   },
      // };
      // if (this.showAssignedOnly) {
      //   defaultQuery.unassignedAt = { $exists: false };
      // }

      episodeService.get(this.patientId).then((res) => {
        this.episodes = res.docs;
        this.treatmentAssignments = this.episodes.flatMap(
          (episode) => episode.treatments
        );
      });
    },

    submit() {
      const alreadyHasAssignedProtocols = this.treatmentAssignments.filter(
        ({ unassignedAt }) => !unassignedAt
      );

      if (alreadyHasAssignedProtocols && alreadyHasAssignedProtocols.length) {
        const protocolsNames = alreadyHasAssignedProtocols
          .map(({ treatment }) => capitalize(treatment.name))
          .join(", ");

        const message = `El paciente ya tiene protocolo/s asignados: <br/>
            <b>${protocolsNames}</b>
            <br/>
            Desea continuar?`;

        this.$confirm(message, "Confirmar", {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          dangerouslyUseHTMLString: true,
          type: "warning",
        })
          .then(() => {
            this.assignTreatmentToPatient();
          })
          .catch(() => {});
      } else {
        this.assignTreatmentToPatient();
      }
    },

    assignTreatmentToPatient() {
      this.isPosting = true;
      return episodeService
        .createEpisodeTreatment({
          patientId: this.patientId,
          treatmentId: this.newTreatment.id,
          // The datepicker does not have time and when the selected date is today could cause validations problems (depending time of data)
          startsAt: isToday(this.newTreatment.startsAt)
            ? new Date()
            : this.newTreatment.startsAt,
        })
        .then(() => {
          segmentService.track({ name: "TreatmentAssigned Created" });
          this.newTreatment = { id: "", startsAt: "" };
          return this.getTreatmentAssignmets();
        })
        .finally(() => {
          this.isPosting = false;
        });
    },

    isUnassignDisabled(treatmentId) {
      return this.treatmentAssignmentsDisabled.some((id) => id === treatmentId);
    },

    unassignTreatment(treatmentAssignment) {
      const { _id, treatment } = treatmentAssignment;
      const { name } = { ...treatment };
      const msg = name ? `: "${name}"` : "";
      this.$confirm(
        `¿Desea desasignar el protocolo${msg}?`,
        "Desasignar Protocolo",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      ).then(() => {
        segmentService.track({ name: "TreatmentAssignment Removed" });
        this.treatmentAssignmentsDisabled.push(_id);

        episodeService
          .removeEpisodeTreatment({
            patientId: this.patientId,
            episodeTreatmentId: _id,
          })
          .then(this.getTreatmentAssignmets)
          .finally(() => {
            this.treatmentAssignmentsDisabled =
              this.treatmentAssignmentsDisabled.filter((id) => id !== _id);
          });
      });
    },
  },
};
</script>

<template lang="pug">
  ValidationObserver(v-slot="{handleSubmit}")
    form.modal.treatment-detail(@submit.prevent="handleSubmit(submit)")
      header.modal__header
        h2.modal__title Protocolo
        .modal__actions
          el-button(type="info", @click="closeModal('patient')") Salir
          el-button.border(
            type="primary",
            native-type="submit",
            :loading="isPosting"
          ) Asignar

      .modal__content(v-if="isFetching")
        spinner
      .modal__content(v-else)
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="note_add")
              h3.sign__title Asignar Nuevo Protocolo
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label Protocolo:
                  ValidationProvider(name="Protocolo", rules="required", v-slot="{ errors }")
                    el-select(
                      v-model="newTreatment.id",
                      filterable,
                      default-first-option
                    )
                      el-option(
                        v-for="treatment in parsedTreatments",
                        :key="treatment._id",
                        :label="treatment.name",
                        :value="treatment._id",
                        :disabled="treatment.disabled"
                      )
                    span.has-error {{ errors[0] }}
              .modal__row
                fieldset.modal__field
                  label.label Seleccione una fecha
                  ValidationProvider(name="Fecha", rules="required|includeToday", v-slot="{ errors }")
                    el-date-picker(
                      v-model="newTreatment.startsAt"
                      :disabled-time="false"
                      lang="es",
                      :class="{ 'has-error': errors[0] }",
                      :not-before="new Date()"
                    )
                    span.has-error {{ errors[0] }}
            article.modal__fields
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="description")
              h3.sign__title Protocolos Asignados
            article.modal__fields
              //.modal__row
                fieldset.modal__field
                  label.label Desde:
                  el-date-picker(
                    lang="es",
                    v-model="filterRange.from",
                    @clear="(filterRange.from = '')"
                  )
                fieldset.modal__field
                  label.label Hasta:
                  el-date-picker(
                    lang="es",
                    v-model="filterRange.to",
                    @clear="(filterRange.to = '')"
                  )
              .modal__row
                fieldset.modal__field
                  el-checkbox(
                    v-if="!!treatmentAssignments.length"
                    v-model="showAssignedOnly"
                    :label="'Mostrar solo Protocolos asignados'"
                  )

              .modal__row
                fieldset.modal__field
                  h2.no-treatments(
                    v-if="!filteredTreatmentAssignments.length && (filterRange.from || filterRange.to)"
                  )
                    | No hay protocolos asignados en el rango de fechas
                  h2.no-treatments(v-else-if="!filteredTreatmentAssignments.length")
                    | No tiene protocolos asignados
                  .box(v-else)
                    table
                      thead
                        tr
                          th Nombre
                          th Fecha de asignación
                          th Fecha de desasignación
                          th Primera Tarea
                          th Última Tarea
                          th Acciones
                      tbody
                        tr(
                          v-for="(treatmentAssignment, index) in filteredTreatmentAssignments",
                          :key="treatmentAssignment._id"
                        )
                          //- td {{ treatmentAssignment._id }}
                          td {{ treatmentAssignment.treatment ? treatmentAssignment.treatment.name : 'ERROR' | capitalize }}
                          td {{ treatmentAssignment.createdAt | formatDateAndTime }}
                          td {{ treatmentAssignment.unassignedAt | formatDateAndTime }}
                          td {{ treatmentAssignment.firstTaskScheduledAt | formatDateAndTime }}
                          td {{ treatmentAssignment.lastTaskScheduledAt | formatDateAndTime }}
                          td.actions
                            .actions-container
                            button.button.button--action(
                              v-if="!treatmentAssignment.unassignedAt"
                              @click.prevent="unassignTreatment(treatmentAssignment)",
                              :disabled="isUnassignDisabled(treatmentAssignment._id)"
                              v-tooltip="'Desasignar protocolo'"
                            )
                              micon(name="delete_forever")
</template>

<style lang="scss">
.treatment-detail {
  .box {
    height: auto;
    max-height: 677px;
  }
}
</style>
