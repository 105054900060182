<script>
import { TASKS_TYPES } from "@/services/constants";

export default {
  name: "CalendarCell",

  data() {
    return {
      TASKS_TYPES,
    };
  },

  props: {
    tasks: {
      type: Array,
      default: () => [],
    },

    appointments: {
      type: Array,
      default: () => [],
    },

    day: {
      type: String,
      required: true,
    },
  },

  methods: {
    getDay(date) {
      const dateArray = date.split("-");
      return dateArray[dateArray.length - 1];
    },

    goToTaskDetail(task) {
      if (!task.type) {
        return;
      }

      const name = "update-patient-task";
      const taskID = task._id;
      const taskType = task.type;
      const params = { taskID, taskType };
      this.$router.push({ name, params });
    },

    goToAppointmentDetail(appointment) {
      if (!appointment.type) {
        return;
      }

      //const name = "update-patient-task";
      const appointmentID = appointment._id;
      const params = { appointmentID };
      this.$router.push({ name, params });
    },
  },

  computed: {
    hasTasks() {
      return Object.keys(this.tasks).length;
    },
  },
};
</script>

<template lang="pug">
  .patient-calendar-cell
    span.day {{ getDay(day) }}
    .tasks-container(v-if="hasTasks")
      .task-block(v-for="task in tasks" v-if="tasks.length" :class="task.type" :key="task._id")
        span
          micon(:name="TASKS_TYPES[task.type].icon" width="25px" heigth="25px")
          | {{ task.scheduledAt | time }}:
          | {{ task.name }}
</template>

<style lang="scss">
.el-calendar-table td.is-today .day {
  font-weight: bold;
}

.patient-calendar-cell {
  padding: 8px;

  .tasks-container {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .task-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 3px;
    margin: 2px;
    border-radius: 2px;
    background: $primary;
    color: $white;
    transition: opacity 250ms;
  }

  .vitals {
    background: $vital;
    .micon {
      fill: $white;
    }
  }
  .checkins {
    background: $checkin;
    .micon {
      fill: $white;
    }
  }
  .medications {
    background: $medication;
    .micon {
      fill: $white;
    }
  }
  .appointments {
    background: $appointment;
    .micon {
      fill: $white;
    }
  }
  .recommendations {
    background: $recommendation;
    .micon {
      fill: $white;
    }
  }
  .exercises {
    background: $exercise;
    .micon {
      fill: $white;
    }
  }
  .reports {
    background: $report;
    .micon {
      fill: $white;
    }
  }
}
</style>
