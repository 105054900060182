import api from "./api";

const paymentService = {};

const endpoint = "/payments";

paymentService.get = function (query = {}) {
  return api.get(endpoint, { params: query }).then((res) => res.data);
};

paymentService.getCsv = function (query = {}) {
  return api
    .get(`${endpoint}/csv`, { params: query, responseType: "blob" })
    .then((res) => res.data);
};

paymentService.getServices = function () {
  return api.get(`${endpoint}/services`).then((res) => res.data);
};

paymentService.post = function (service) {
  return api.post(`${endpoint}`, service).then((res) => res.data);
};

paymentService.updatePayment = function (payment) {
  return api.put(`${endpoint}/${payment._id}`, payment).then((res) => res.data);
};

export default paymentService;
