<script>
import model from "@/mixins/model";
import { PROVINCES } from "@/services/constants";
import { theme } from "@/services/config";
import { capitalize } from "@/services/helper";

export default {
  name: "UserAddressSection",

  props: {
    cities: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
    },
  },

  data() {
    return {
      provinces: PROVINCES,
      theme,
      isLoading: false,
      capitalize,
    };
  },

  mixins: [model],

  methods: {},

  computed: {
    districts() {
      return this.cities?.find(
        (city) => city.name === this.model?.address?.city
      )?.districts;
    },

    neighborhoods() {
      return this.cities
        ?.find((city) => city.name === this.model?.address?.city)
        ?.districts?.find(
          (district) => district.name === this.model?.address?.district
        )?.neighborhoods;
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="location_on")
      h3.sign__title Dirección
    article.modal__fields
      .modal__row
        fieldset.modal__field
          label.label Dirección:
          el-input(v-model="model.address.street", name="street")
        fieldset.modal__field
          label.label Número:
          el-input(v-model="model.address.buildingNumber", name="buildingNumber")
        fieldset.modal__field
          label.label Número de piso:
          el-input(v-model="model.address.apartmentFloor", name="apartmentFloor")
        fieldset.modal__field
          label.label Número apartamento:
          el-input(v-model="model.address.apartmentNumber", name="apartmentNumber")
      .modal__row
        fieldset.modal__field
          label.label Entre calle 1:
          el-input(v-model="model.address.betweenStreets[0]", name="betweenStreet1")
        fieldset.modal__field
          label.label Entre calle 2:
          el-input(v-model="model.address.betweenStreets[1]", name="betweenStreet2")

      //- dash custom component    
      .modal__row(v-if='theme === "dash"')
        fieldset.modal__field
          label.label * Estado:
          ValidationProvider(name="localidad" :rules="type === 'user' ? 'required': ''" v-slot="{errors}")
            el-select(v-model="model.address.city" placeholder="" name="city" filterable)
              el-option(
                v-for="city in cities"
                :key="city.value"
                :label="capitalize(city.name)"
                :value="city.name"
              )
            span.has-error {{errors[0]}}
        fieldset.modal__field
          label.label * Municipio:
          ValidationProvider(name="district" :rules="type === 'user' ? 'required': ''" v-slot="{errors}")
            el-select(v-model="model.address.district" placeholder="" name="district" filterable)
              el-option(
                v-for="district in districts"
                :key="district.value"
                :label="capitalize(district.name)"
                :value="district.name"
              )
            span.has-error {{errors[0]}}
        fieldset.modal__field
          label.label Localidad:
          ValidationProvider(name="neighborhood" :rules="type === 'user' ? 'required': ''" v-slot="{errors}")
            el-select(v-model="model.address.neighborhood" name="neighborhood" placeholder="" filterable)
              el-option(
                v-for="neighborhood in neighborhoods"
                :key="neighborhood.value"
                :label="capitalize(neighborhood.name)"
                :value="neighborhood.name"
              )
            span.has-error {{errors[0]}}
          
      //- dash custom component    

      .modal__row(v-else)
        fieldset.modal__field
          label.label * Provincia:
          el-select#province(v-model="model.address.district" placeholder="" filterable default-first-option)
            el-option(
              v-for="province in provinces"
              :key="province"
              :label="province"
              :value="province"
            )
        fieldset.modal__field
          label.label * Localidad:
          ValidationProvider(name="localidad" :rules="type === 'user' ? 'required': ''" v-slot="{errors}")
            el-input(v-model="model.address.city")
            span.has-error {{errors[0]}}
        fieldset.modal__field
          label.label Barrio:
          el-input(v-model="model.address.neighborhood", name="neighborhood")

        //- fieldset.modal__field
          label.label * País:
          el-select(v-model="model.address.country" filterable)
            el-option(value="Argentina" label="Argentina")
            el-option(value="Brasil" label="Brasil")
            el-option(value="Chile" label="Chile")
            el-option(value="Colombia" label="Colombia")
            el-option(value="Uruguay" label="Uruguay")
            el-option(value="" label="province")

        fieldset.modal__field
          label.label Código Postal:
          el-input(v-model="model.address.postalCode")
</template>
