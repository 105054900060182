<script>
import { mapGetters } from "vuex";
import pagination from "@/mixins/pagination";

import debounce from "debounce";
import helperService from "@/services/helper";
import patientService from "@/services/patient";
import userService from "@/services/user";
import inviteService from "@/services/invite";
import segmentService from "@/services/segment";

import Spinner from "@/components/ui/Spinner";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "PatientList",

  mixins: [pagination],

  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: false,
      isLoadingDoctors: false,
      isLoadingManagers: false,
      searchInput: "",
      patients: [],
      doctors: [],
      managers: [],
      doctorFilter: null,
      // managerFilter: null,
    };
  },

  computed: {
    ...mapGetters(["isDoctor", "isManager", "user"]),
  },

  watch: {
    doctorFilter(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      // if (!this.managerFilter) {
      //   const doctor = this.doctors.find((d) => d._id === newVal);
      //   if (doctor && doctor.supervisor) {
      //     this.managerFilter = doctor.supervisor;
      //   }
      // }

      this.getPatients();
    },

    // managerFilter(newVal, oldVal) {
    //   if (newVal === oldVal) {
    //     return;
    //   }

    //   if (this.doctorFilter) {
    //     const doctor = this.doctors.find((d) => d._id === this.doctorFilter);
    //     if (doctor && doctor.supervisor !== newVal) {
    //       this.doctorFilter = "";
    //     }
    //   }

    //   this.getPatients();
    //   this.getDoctors();
    // },
  },

  created() {
    this.getPatients();

    if (!this.isDoctor) {
      this.getDoctors();
    }

    if (!this.isDoctor && !this.isManager) {
      this.getManagers();
    }

    this.$bus.$on("patient-update", (patient) => {
      const index = this.patients.findIndex((p) => p._id === patient._id);
      if (index >= 0) {
        this.$set(this.patients, index, patient);
      } else {
        this.patients.unshift(patient);
      }
    });
  },

  methods: {
    getDoctors() {
      this.isLoadingDoctors = true;

      userService
        .getDoctors()
        .then(({ docs }) => (this.doctors = docs))
        .finally(() => (this.isLoadingDoctors = false));
    },

    getManagers() {
      this.isLoadingManagers = true;

      userService
        .getManagers()
        .then(({ docs }) => (this.managers = docs))
        .finally(() => (this.isLoadingManagers = false));
    },

    getPatients(query = {}) {
      this.isLoading = true;

      const defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };

      defaultQuery.doctors = this.doctorFilter || undefined;

      patientService
        .get(defaultQuery)
        .then((res) => {
          this.patients = res.docs;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    debounceInput: debounce(function () {
      const query = {
        offset: 0,
        limit: this.pagination.limit,
      };

      const input = this.searchInput && this.searchInput.split(" ");
      const useSearchIndex = input && (input.length === 1 || input.length > 3);

      // Search up to 3 words (Double names or double last names)
      const useNameIndex = input && input.length > 1 && input.length <= 3;

      if (useSearchIndex) {
        query.$text = {
          $search: this.searchInput,
        };
      } else if (useNameIndex) {
        Object.assign(query, helperService.createNameQuery(this.searchInput));
      }

      this.getPatients(query);
    }, DEBOUNCE_MS_TIMEOUT),

    getFullName(patient) {
      return helperService.getFullName(patient);
    },

    getPatientDoctorsNames(doctors) {
      return helperService.getPatientDoctorsNames(doctors);
    },

    lockPatient(patient) {
      this.$confirm(
        `¿Desea bloquear a: ${helperService.getFullName(patient)}?`,
        "Bloquear",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          segmentService.track({ name: "Patient Locked" });
          userService.lock(patient._id).then(() => {
            const index = this.patients.findIndex((p) => p._id === patient._id);
            this.$set(this.patients, index, {
              ...this.patients[index],
              lockedAt: new Date(),
            });
          });
        })
        .catch(() => {});
    },

    unlockPatient(id) {
      segmentService.track({ name: "Patient Unlocked" });
      userService.unlock(id).then(() => {
        const index = this.patients.findIndex((p) => p.user._id === id);
        const modifiedPatient = { ...this.patients[index] };
        delete modifiedPatient.lockedAt;
        this.$set(this.patients, index, modifiedPatient);
      });
    },

    reSendInvite(email) {
      inviteService
        .sendInvite(email)
        .then(() =>
          this.$message.success(`Se reenvío una invitación a ${email}`)
        );
    },

    onPageChange() {
      this.getPatients();
    },

    goToPatientDashboard(id) {
      helperService.callIfNoSelectedText(() =>
        this.$router.push({
          name: "patient-dashboard",
          params: { id },
        })
      );
    },

    getPatientStatus(user) {
      const { patient } = user;

      if (patient?.status === "closed") {
        return "Cerrado";
      }

      const lastEvent = patient?.lastEvent;
      if (!lastEvent) {
        return "Paciente nuevo";
      }

      const lastEventWasClosedStatus = lastEvent.status === "closed";
      const isActive = patient.status === "active";

      // This happens when the patient has an active episode but the last event
      // was a closed event from another episode.
      if (isActive && lastEventWasClosedStatus) {
        return "Paciente nuevo";
      }

      const name = lastEvent && lastEvent.form && lastEvent.form.name;
      return name;
    },
  },
};
</script>

<template lang="pug">
section.patients-list
  header.headline
    //- .headline__title
      h1
        | Pacientes
        small(v-if="pagination.total")  ({{ pagination.total }})

      hr
    .headline__actions
      .left
        el-input.search__input(
          prefix-icon="el-icon-search"
          v-on:input="debounceInput"
          v-model="searchInput"
          placeholder="Buscar"
          :clearable="true"
        )

        //- el-select(
        //-   v-if="!isManager && !isDoctor"
        //-   v-model="managerFilter"
        //-   filterable
        //-   clearable
        //-   :loading="isLoadingManagers"
        //-   loading-text="Cargando Coordinadores..."
        //-   placeholder="Coordinador"
        //-   default-first-option
        //- )
        //-   el-option(label="Sin Coordinador" :value="0")
        //-   el-option(
        //-     v-for="manager in managers"
        //-     :key="manager._id"
        //-     :label="getFullName(manager)"
        //-     :value="manager._id"
        //-   )
        el-select(
          v-if="!isDoctor"
          v-model="doctorFilter"
          filterable
          clearable
          :loading="isLoadingDoctors"
          loading-text="Cargando Médicos..."
          placeholder="Médico"
          default-first-option
        )
          el-option(label="Sin Médico" :value="'NONE'")
          el-option(
            v-for="doctor in doctors"
            :key="doctor._id"
            :label="getFullName(doctor)"
            :value="doctor._id"
          )

      .right
        router-link.button.button--blue(
          v-auth="'patient.create'"
          :to="{name: 'create-patient'}"
        )
          micon(name="add_circle_outline").button__icon
          span.button__text Nuevo Paciente
  .box
    .box__content--stretch
      table
        thead
          tr
            th Paciente
            th Edad
            th Documento
            //- th Número de Socio
            th Estado
            //- th(v-if="!isDoctor") Médico
            th Acciones
        tbody
          spinner(v-if="isLoading")
          tr(v-else v-for="(patient, index) in patients" :key="patient._id" @click="goToPatientDashboard(patient._id)")
            td
              b {{ getFullName(patient) }}
            td {{ patient.bornAt | age }}
            td {{ patient.governmentId ? patient.governmentId.number : 'N/A'}}
            //- td {{ patient.patient && patient.patient.medicalInsuranceNumber || 'N/A'}}
            td {{ getPatientStatus(patient) }}
            //- td(v-if="!isDoctor") {{ patient.doctors && patient.doctors.length ? getPatientDoctorsNames(patient.doctors) : 'Sin Médico' }}
            td.actions
              .actions-container
                el-dropdown(trigger="click")
                  .button.button--action.el-dropdown-link(@click.stop)
                    micon(name="more_horiz")
                  el-dropdown-menu(slot="dropdown")
                    el-dropdown-item(v-if="!isDoctor")
                      router-link(:to="{ name: 'update-patient', params: { id: patient._id }}")
                        micon(name="edit")
                        | Editar paciente
                    el-dropdown-item(v-auth="'treatment.assign'")
                      router-link(:to="{ name: 'assign-treatment-patient', params: { id: patient._id }}")
                        micon(name="note_add")
                        | Asignar Protocolo
                    el-dropdown-item(v-auth="'patient.access-private'")
                      router-link(:to="{ name: 'patient-tasks', params: { id: patient._id }}")
                        micon(name="calendar")
                        | Ver tareas
                    el-dropdown-item(v-auth="'patient.access-private'")
                      router-link(:to="{ name: 'patient-dashboard', params: { id: patient._id }}")
                        micon(name="insert_chart_outlined")
                        | Ver actividad
                    el-dropdown-item(v-show="!patient.lockedAt" v-auth="'patient.update'")
                      div(@click="lockPatient(patient)")
                        micon(name="lock")
                        | Bloquear paciente
                    el-dropdown-item(v-show="patient.lockedAt" v-auth="'patient.update'")
                      div(@click="unlockPatient(patient._id)")
                        micon(name="lock_open")
                        | Desbloquear paciente
                    el-dropdown-item(v-if="!patient.passwordSetAt" v-auth="'patient.update'")
                      div(@click="reSendInvite(patient.email)")
                        micon(name="email")
                        | Reenviar invitación

    pagination(
      :isLoading="isLoading"
      :limit="pagination.limit"
      :total="pagination.total"
      @pagination="setPagination"
    )
</template>

<style lang="scss" scoped>
tbody td:hover {
  cursor: pointer;
}

small {
  margin-right: 5px;
  font-size: 10px;
  font-weight: 700;
}
</style>
